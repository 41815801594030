import { ModelType } from 'Auth/schemas/newPasswordFormModel'
import password from 'lib/validators/password'
import passwordConfirmation from 'lib/validators/passwordConfirmation'
import required from 'lib/validators/required'

export default (model: ModelType) => ({
  password: [
    required,
    password,
  ],
  password_confirmation: [
    required,
    passwordConfirmation(model.password),
  ],
})
