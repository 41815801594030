import { render, staticRenderFns } from "./AuthNewPasswordForm.vue?vue&type=template&id=37a0cdf4&scoped=true&"
import script from "./AuthNewPasswordForm.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AuthNewPasswordForm.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./AuthNewPasswordForm.vue?vue&type=style&index=0&id=37a0cdf4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a0cdf4",
  null
  
)

export default component.exports