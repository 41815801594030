/* eslint-disable camelcase */

export const model = () => ({
  password: '',
  password_confirmation: '',
})

export type ModelType = {
  password: string
  password_confirmation: string
}
